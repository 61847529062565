import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CLHbid = makeShortcode("CLHbid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Registering to Bid`}</h1>
    <p>{`Prior to the auction day ensure that that you go online and `}<a href="https://auctions.clhbid.com/register">{`register to bid`}</a>{`. The process is simple and ensures your bids will remain secure using your `}<CLHbid mdxType="CLHbid" />{` account. You will be provided with a password to use each time you log into your `}<CLHbid mdxType="CLHbid" />{` account.`}</p>
    <p>{`In the event you have any concerns about registering please `}<a to="/contact">{`contact us`}</a>{`.`}</p>
    <p>{`For tips on how to register, please watch the video below:`}</p>
    <div className="embed-responsive aspect-ratio-16-9">
  <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/F93929-_8yQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      